<template>
    <div class="box">
        <h2>
            Prüfung zuweisen: {{ crefoAssignment.label }}
            <small>{{ crefoAssignment.id }}</small>
        </h2>
        <div class="body">
            <table>
                <thead>
                    <tr>
                        <th style="width: 10rem;">Start</th>
                        <th style="width: 20rem;">Ende</th>
                        <th>Prüfung</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(rule, index) in crefoAssignment.rules" v-bind:key="index">
                        <td>
                            <span v-if="index > 0">
                                {{ crefoAssignment.rules[index - 1].until }}
                            </span>
                            <span v-if="index === 0">
                                0
                            </span>
                        </td>
                        <td>
                            <div class="form-group form-float no-error">
                                <input v-model="rule.until" :name="'rule_until_' + index" v-validate="'required|numeric'" :id="'rule_until_' + index" placeholder=" " type="text" />
                                <label :for="'rule_until_' + index">Bis</label>
                                <span class="error" v-show="errors.has('rule_until_' + index)">{{ errors.first("rule_until_" + index) }}</span>
                            </div>
                        </td>
                        <td>
                            <div class="form-group form-float form-select no-error">
                                <select placeholder=" " v-model="rule.check" :id="'rule_check_' + index">
                                    <option value=""></option>
                                    <option v-for="c in crefoChecks.results" v-bind:key="c.id" v-bind:value="c.id">
                                        {{ c.label }}
                                    </option>
                                </select>
                                <label :for="'rule_check_' + index">Prüfung</label>
                            </div>
                        </td>
                        <td><i @click="removeRule(index)" class="delete fal fa-trash-alt"></i></td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <span class="add" @click="addRule()"><i class="add fal fa-plus-circle"></i> Hinzufügen</span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <hr />

            <div class="button-row">
                <button @click="save()" type="button" class="primary size-l"><i class="fal fa-save"></i> Speichern</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            crefoAssignment: {
                id: "",
                label: "",
                rules: []
            },
            crefoChecks: {
                results: []
            }
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        addRule() {
            this.crefoAssignment.rules.push({ until: null, check: "" });
        },
        removeRule(index) {
            this.crefoAssignment.rules.splice(index, 1);
        },
        save() {
            var rules = [];
            for (var r = 0; r < this.crefoAssignment.rules.length; r++) {
                let until = parseInt(this.crefoAssignment.rules[r].until, 10);
                if (isNaN(until)) {
                    until = null;
                }
                rules.push({
                    until: until,
                    check: this.crefoAssignment.rules[r].check
                });
            }

            this.$api.put("/crefo/assignment/" + this.$route.params.id, rules).then(
                () => {
                    this.$snotify.success("Zuordnung gespeichert");
                },
                () => {
                    this.$snotify.error("Speichern fehlgeschlagen");
                }
            );
        },
        fetchData() {
            this.fetchAssignment();
            this.fetchCrefoChecks();
        },
        fetchAssignment() {
            this.$api.get("/crefo/assignment/" + this.$route.params.id).then(
                response => {
                    if (response.data.data.rules === null) {
                        response.data.data.rules = [];
                    }

                    for (let r = 0; r < response.data.data.rules.length; r++) {
                        if (response.data.data.rules[r].check === null) {
                            response.data.data.rules[r].check = {
                                id: ""
                            };
                        }
                    }

                    this.crefoAssignment = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Zuordnung");
                }
            );
        },
        fetchCrefoChecks() {
            let params = {
                page: 1,
                pageSize: 1000,
                q: ""
            };

            this.$api.get("/crefo/checks", { params: params }).then(
                response => {
                    this.crefoChecks = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Prüfungen");
                }
            );
        }
    }
};
</script>
